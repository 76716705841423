import { FC, useCallback, useMemo, useState } from "react";
import { Button, Loader } from "@storybook";

import image from "assets/svg/approve.svg";
import AppleLogin from "react-apple-login";
import { PREVENT_APPLE_LOGIN, approveTexts } from "./constant";
import { isMacOrIOS } from "utils";

interface IProps {
  handleSelectOption: (e: "accept" | "reject", cb: any) => void;
  code: string;
  hostType?: string;
  isLoading: boolean;
}

export type IOption = "accept" | "reject";

interface IButtonsRow {
  label: string;
  option: IOption;
}

const buttonsRow: IButtonsRow[] = [
  {
    label: "Approve",
    option: "accept",
  },
  {
    label: "Deny",
    option: "reject",
  },
];

export const MobileApprove: FC<IProps> = ({ handleSelectOption, code, hostType, isLoading }) => {
  
  // State to track the currently selected option ("accept" or "reject").
  const [selectedOption, setSelectedOption] = useState("");

  /**
   * Handles the selection of an option (either "accept" or "reject").
   *
   * @param option - The selected option, either "accept" or "reject".
   * @param callback - A callback function to execute after the option is selected.
   */
  const handleSelection = useCallback(
    (option: "accept" | "reject", callback: () => void) => {
      // Custom logic to handle the selected option.
      handleSelectOption(option, callback);

      // Update the state with the selected option.
      setSelectedOption(option);
    },
    [handleSelectOption] // Dependency: Recreates the function when `handleSelectOption` changes.
  );

  const renderButtons = useMemo(() => {
    return isMacOrIOS && !PREVENT_APPLE_LOGIN.includes(window.location.host) && hostType && hostType!=='super' ? (
      <AppleLogin
        clientId="com.simplici.service.login"
        redirectURI={window.location.origin}
        usePopup={true}
        callback={() => ({})} // Catch the response
        scope="email name"
        responseMode="query"
        render={(
          renderProps //Custom Apple Sign in Button
        ) => (
          <>
            {buttonsRow.map(({ label, option }) => (
              <Button
                key={option}
                handleClick={() =>
                  handleSelection(option, renderProps.onClick)
                }
                label={
                  isLoading && option === selectedOption ? (
                    <Loader dimension={20} />
                  ) : (
                    label
                  )
                }
                disabled={isLoading}
                type="button__filled button__filled--secondary button__large button__block MobileApprove__btn"
              />
            ))}
          </>
        )}
      />
    ) : (
      <>
        {buttonsRow.map(({ label, option }) => (
          <Button
            key={option}
            handleClick={() => handleSelection(option, () => ({}))}
            label={
              isLoading && option === selectedOption ? (
                <Loader dimension={20} />
              ) : (
                label
              )
            }
            disabled={isLoading}
            type="button__filled button__filled--secondary button__large button__block MobileApprove__btn"
          />
        ))}
      </>
    );
    // eslint-disable-next-line
  }, [isLoading, selectedOption]);

  return (
    <div className="MobileApprove">
      <div className="MobileApprove__row">
        <div className="MobileApprove__title">
          {approveTexts[code]?.heading}
        </div>
      </div>
      <div className="MobileApprove__row">
        <div className="MobileApprove__logo">
          <img className="MobileApprove__logo" src={image} alt="logo" />
        </div>
        <div className="MobileApprove__description">
          {approveTexts[code]?.description}
        </div>
      </div>
      <div className="MobileApprove__row MobileApprove__btn-row">
        {renderButtons}
      </div>
    </div>
  );
};
